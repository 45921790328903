main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
main > div {
  display: flex;
  flex-direction: row;
}
